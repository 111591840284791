@import '~/styles/variables';

.container {
  width: 100%;
  height: 100%;

  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @include smallTablet {
    gap: 16px;
  }
  @include mobile {
    padding: 0 16px 32px;

    &,
    & * {
      touch-action: pan-y;
    }
  }
}

.leftMenu {
  width: 100%;
  max-width: 144px;
  height: 100%;

  @include smallTablet {
    max-width: 96px;
  }
  @include mobile {
    display: none;
  }
}
.modeButton {
  width: 100%;
  height: 50%;

  padding: 12px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  svg {
    height: 35px;
  }

  span {
    padding: 0;
    font-size: 14px;
    white-space: normal;
    text-align: center;
  }

  &[data-selected='true'] {
    background: $secondaryBlue19;

    span,
    svg {
      color: $blogTextLinkColor !important;
    }
  }
}

.divider {
  width: 1px;
  height: 100%;
  background: $dark30;

  @include mobile {
    display: none;
  }
}

.contentContainer {
  flex: 1;
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
}

.contentHeader {
  width: 100%;
  height: 40px;
  min-height: 40px;

  border-bottom: 1px solid $dark30;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @include mobile {
    overflow-y: hidden;
    overflow-x: auto;

    @include no-scroll-bar();

    &,
    & * {
      touch-action: pan-x;
    }
  }
}
.tabs {
  position: relative;
  width: fit-content;
  height: 100%;

  display: flex;
  flex-direction: row;
}
.tab {
  width: fit-content;
  height: 100%;

  padding: 0 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  opacity: 0.8;
  @include transition(all);

  svg {
    width: 18px;
  }
  span {
    font-family: Quicksand;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    letter-spacing: 0%;
    text-align: center;
    white-space: nowrap;

    @include smallTablet {
      display: none;
    }
    @include mobile {
      display: block;
    }
  }
  span,
  svg * {
    color: $darkThemeText;
    @include transition(all);
  }

  &:hover {
    opacity: 1;
  }
}
.activeTab {
  opacity: 1;

  span,
  svg * {
    color: $secondaryBlue13;
  }
}
.activeTabMarker {
  height: 1px;
  width: 0px;

  position: absolute;
  left: 0;
  bottom: -1px;

  background: $secondaryBlue13;
  @include transition(all);

  @include mobile {
    bottom: 0;
  }
}

.showRomanNumerals {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @include mobile {
    display: none;
  }
}
.showRomanNumeralsText {
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  text-align: left;
  color: $darkThemeText;
  white-space: nowrap;
}
.showRomanNumeralsSwitch {
  &.showRomanNumeralsSwitchActive > div {
    background: $secondaryBlue13;
  }
}

.contentContent {
  flex: 1;
  height: calc(100% - 40px);
}

// ChordEditorChordsInKey

.chordEditorChordsInKey_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.chordEditorChordsInKey_chordGrid,
.chordEditorChordsInKey_chordGridNotInScale {
  height: fit-content !important;
  min-height: 72px;
}
.chordEditorChordsInKey_chordGridNotInScale {
  width: fit-content;
}
